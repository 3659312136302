(function () {
    "use strict";
    svg4everybody();
    $(document).foundation();

    function Digital() {
        this.getSlideNumber = function () {
            var  url = 0;
            var part = document.location.hash.split("#").pop();
            var $htag = $("[data-hash=\"" + part + "\"]");
            if ($htag) {
                url = $htag.data("slideId");
            }

            console.debug("getSlideNumber", part, $htag, url);

            //url = document.location.href.split("#slide").pop();
            if (!$.isNumeric(url)) {
                url = 0;
            }
            return url;
        };
    }

    var DIGITAL = new Digital();

    $(".track-selection").on("click", function () {
        var inputSelect = $(this).data("inputSelect");
        console.log("set inputSelect", inputSelect);

        sessionStorage.setItem("inputSelect", inputSelect);
    });

    var inputSelect = sessionStorage.getItem("inputSelect");

    if (inputSelect != null) {
        console.log("read inputSelect", inputSelect);

        //<input type="radio" checked="checked" name="radioName" value="Tarkvaraarendus" id="radioArendus" class="radio">

        var $input = $("input[value='" + inputSelect + "']");
        if ($input.length) {
            sessionStorage.removeItem("inputSelect");

            $input.prop("checked", true);
        }
    }


    var $page = $("#fullpage");
    if ($(".fp-home").length) {
        $page.fullpage({
            /*
          anchors: [
            "loome-aritarkvara",
            "teenused",
            "referentsid",
            "kliendid",
            "meist",
            "kontakt"
          ],*/
            menu: ".tracking-menu",
            autoScrolling: false,
            fitToSection: false,
            loopHorizontal: false,
            lazyLoad: false,
            animateAnchor: false
        });
    } else {
        $page.fullpage({
            autoScrolling: false,
            fitToSection: false,
            loopHorizontal: false,
            lazyLoad: false
        });
    }
    var path = window.location.pathname,
        $logo_cont = $(".main-logo-cont");

    console.debug("window.location.pathname", window.location.pathname);

    if (path !== "/" && path !== "/en" && path !== "/en/") {
        $logo_cont.removeClass("hidden");
        $page.addClass("fp-services");
        $logo_cont.css("display", "block");
    } else {
        $logo_cont.addClass("hidden");
        $page.addClass("fp-home");
        $logo_cont.css("display", "none");
    }

    if ($("html").hasClass("fp-enabled") && $(".fp-home").length) {
        $("body").css("overflow", "hidden");
        window.onload = function () {
            $("body")
                .addClass("loaded")
                .css("overflow", "auto");
        };
    }
    $(document).ready(function () {
        if ($(".rellax").length) {
            new Rellax(".rellax");
        }
        /*--------------------------------------------------------
                                  OWL
        ---------------------------------------------------------*/
        var owl_settings = {
            items: 1,
            loop: true,
            nav: false,
            autoplay: true,
            dots: false,
            autoplayTimeout: 5000,
            animateOut: "fadeOut"
        };
        $(".owl-home, .logo-carousel, .owl-svg").owlCarousel(
            owl_settings
        );

        // Extend $(".owl-home, .owl-logos") settings to $(".owl-works").
        // Merge defaults and options, without modifying the defaults.
        var $owl_works = $(".owl-works");
        $owl_works.owlCarousel(
            $.extend({}, owl_settings, {
                loop: false,
                nav: true,
                navText: [
                    "<svg><use xlink:href='/images/sprites.svg#arrow-left' /></svg>",
                    "<svg><use xlink:href='/images/sprites.svg#arrow-right' /></svg>"
                ],
                autoplay: false
            })
        );
        $(".owl-office").owlCarousel(
            $.extend({}, owl_settings, {
                loop: false,
                dots: true,
                autoplay: false
            })
        );

        // Probably not necessary. This was intended to be used with ajax.
        /*
        $owl_works.find(".owl-slide").hide();
        $owl_works.find(".owl-item.active .owl-slide").show();
        $owl_works.find(".owl-item.active").next().find(".owl-slide").show();
        */

        $owl_works.on("changed.owl.carousel", function (e) {
            var $this = $(this),
                $body = $("body"),
                url = DIGITAL.getSlideNumber(),
                $thumbnails = $(".thumbnails");

            $owl_works.find(".owl-item.active .owl-slide").show();
            //das
            // Probably not necessary. This was intended to be used with ajax.

            /*

            setTimeout(function () {
              var next = $(".owl-item.active", $this).next(),
                prev = $(".owl-item.active", $this).prev();
              prev.find($(".owl-slide")).show();
              prev
                .prev()
                .find($(".owl-slide"))
                .hide();
              next.find($(".owl-slide")).show();
              next
                .next()
                .find($(".owl-slide"))
                .hide();
            }, 1);
            */
            // Change body class when slider needs black styles.
            $("#colour_mode_" + url).val() === "black" ? $body.addClass("slide-black")
                : $body.removeClass("slide-black");

            if (url !== $(".slider-input").val()) {
                var slider = new Foundation.Slider($(".slider"), {
                    initialStart: url
                });
            }
            $thumbnails.find("a").removeClass("active");
            $thumbnails.find('a[href="#slide${url}"]').addClass("active");
        });
        $thumbnails = $(".thumbnail-pic");
        $thumbnails.find("a").click(function() {
            $thumbnails.find("a").removeClass("active");
            $( this ).addClass("active");
        });
        var owl_responsive = $(".owl-logos-responsive");
        if ($(window).width() < 854) {
            owl_responsive.owlCarousel(owl_settings);
            $(".section-works").addClass("fp-auto-height");
        } else {
            owl_responsive.addClass('off');
        }
        $(window).resize(function () {
            if ($(window).width() < 854) {
                if (owl_responsive.hasClass('off')) {
                    owl_responsive.owlCarousel(owl_settings);
                    owl_responsive.removeClass('off');
                    $(".section-works").addClass("fp-auto-height");
                }

            } else {
                if (!owl_responsive.hasClass('off')) {
                    owl_responsive.addClass('off').trigger('destroy.owl.carousel');
                    owl_responsive.find('.owl-stage-outer').children(':eq(0)').unwrap();
                    $(".section-works").removeClass("fp-auto-height");
                }

            }

        });

        /*--------------------------------------------------------
                                  MENU
        ---------------------------------------------------------*/
        var $body_burger = $("body, .burger"),
            $body = $("body"),
            $burger_wrapper = $("#burgerWrapper"),
            $burger = $(".burger"),
            $desktop_topbar = $(".desktop-topbar"),
            $topbar_forms = $("#topbarForms"),
            $thumbnails = $(".thumbnails"),
            $thumbnail_button = $(".thumbnail-button"),
            $topbar_info = $("#topbarInfo");
        $(".burger, .topbar").click(function () {
            console.log("#1 .burger, .topbar");
            $body_burger.toggleClass("open");
            if ($burger.hasClass("open-cross")) {
                $burger.removeClass("open open-cross");
                $burger.removeClass("open-thmb");
                $topbar_forms.removeClass("fade-in-nav");
                if ($burger_wrapper.hasClass("animate-opacity")) {
                    $burger_wrapper.removeClass("animate-opacity");
                }
                if ($thumbnails.hasClass("fade-in-nav")) {
                    $thumbnails.removeClass("fade-in-nav");
                    $body.removeClass("open");
                    $body.removeClass("open-thmb");
                    $thumbnail_button.removeClass("hide");
                }
                if (
                    $(".form-msg-block").hasClass("fade-in-msg-block") ||
                    $(".form-msg-block").hasClass("fade-in-up-msg-block")
                ) {
                    $(".form-msg-block")
                        .removeClass("fade-in-msg-block")
                        .removeClass("fade-in-up-msg-block");
                    $(".form-msg")
                        .text("")
                        .removeClass("error")
                        .removeClass("success");
                    $(".msg-check").removeClass("pulse-check");
                }
            } else {
                $topbar_info.toggleClass("fade-in-nav");
            }
            if ($desktop_topbar.hasClass("animate-top")) {
                $desktop_topbar.removeClass("animate-top");
            }
            $desktop_topbar.show();
        });

        $(".inner").on("click", function (e) {
            e.stopPropagation();
        });

        $(".button.ask-us").on("click", function () {
            if ($("body").hasClass("open")) {
                $(".burger").trigger("click");
            }
            $("html, body").animate({
                scrollTop: $("#topbarForms").offset().top - $(".desktop-topbar").height()
            });
        });

        $("#myMenu li").on("click", function () {
            setTimeout(function () {
                $body_burger.removeClass("open");
                $topbar_info.removeClass("fade-in-nav");
            }, 300);
        });

        $("#root-menu li").on("click", function () {
            setTimeout(function () {
                $body_burger.removeClass("open");
                $topbar_info.removeClass("fade-in-nav");
            }, 300);
        });

        $thumbnail_button.on("click", function () {
            //$desktop_topbar.hide();
            if (window.innerWidth > 1023) {
                $desktop_topbar.addClass("animate-top");
                $burger_wrapper.addClass("animate-opacity");
            } else {
                $desktop_topbar.hide();
            }
            $body.addClass("open-thmb");
            $thumbnails.addClass("fade-in-nav");
            $burger.addClass("open-cross");
            $thumbnail_button.addClass("hide");

        });

        $(".thumbnails a").on("click", function () {
            console.log("#1 .thumbnails a");

            if ($desktop_topbar.hasClass("animate-top")) {
                $desktop_topbar.removeClass("animate-top");
                $burger_wrapper.removeClass("animate-opacity");
            }
            $desktop_topbar.show();
            $burger.removeClass("open open-cross");
            $body.removeClass("open");
            $body.removeClass("open-thmb");
            $thumbnails.removeClass("fade-in-nav");
            $thumbnail_button.removeClass("hide");
        });
        $(".owl-carousel").addClass("first-slide");
        var sliderNr = 0;
        $(".slider").on("changed.zf.slider", function () {
            sliderNr++;
            var sval = $(".slider-input").val();
            if (sval == 0) {
                $(".owl-carousel").addClass("first-slide");
            } else {
                $(".owl-carousel").removeClass("first-slide");
            }
            if (sliderNr > 1) {
                var slide_id = $(".slider-input").val();
                var $htag = $("[data-slide-id=\"" + slide_id + "\"]");
                if ($htag) {
                    var url = $htag.data("hash");
                    document.location = "#" + url
                }

                //document.location = "#slide" + $(".slider-input").val();

                setTimeout(function () {
                    $owl_works.find(".owl-item.active .owl-slide").show();
                }, 100);
            }
        });
        $(".thumbnail-pic").on("click", function () {
            $("html, body").animate(
                {
                    scrollTop:
                        $(".owl-works").offset().top - $(".desktop-topbar").height() + 2
                },
                300
            );
            setTimeout(function () {
                var url = DIGITAL.getSlideNumber();
                if (project_slider) {
                    $(".slider").foundation("destroy");
                }
                var project_slider = new Foundation.Slider($(".slider"), {
                    initialStart: url
                });
                $owl_works.find(".owl-item.active .owl-slide").show();
            }, 100);
        });
        /*----------------------------------------------------------
                                  TWEEN GSAP
        ---------------------------------------------------------*/
        var is_IE =
            !!navigator.userAgent.match(/Trident/g) ||
            !!navigator.userAgent.match(/MSIE/g) ||
            /Edge\/\d./i.test(navigator.userAgent);
        var controller = new ScrollMagic.Controller();
        if (is_IE) {
            $(".bg-parallax--services, .bg-parallax--menu").css({
                "background-attachment": "unset"
            });
        }

        if ($(".fp-home").length) {
            $(".footer-lower").hide();
            var tween_bg_fixed = TweenLite.to(".svg-arrow-down", 1, {
                autoAlpha: 0,
                ease: Linear.easeNone
            });
            var bg_fixed_scene = new ScrollMagic.Scene({
                triggerElement: ".section-intro",
                triggerHook: 0,
                duration: "200"
            });
            bg_fixed_scene.setTween(tween_bg_fixed).addTo(controller);

            var tween_heading = TweenLite.to("#headingFadeOut", 1, {
                autoAlpha: 0,
                ease: Linear.easeNone
            });
            var heading_scene = new ScrollMagic.Scene({
                triggerElement: "#headingFadeOut",
                triggerHook: 0.4,
                duration: "200"
            });
            heading_scene.setTween(tween_heading).addTo(controller);

            var btn_scene = new ScrollMagic.Scene({
                triggerElement: ".btn-dev",
                triggerHook: 0.9,
                reverse: false
            });
            btn_scene.setClassToggle(".btn-dev", "animate").addTo(controller);

            var bg_wrapper_scene = new ScrollMagic.Scene({
                triggerElement: ".section-works",
                triggerHook: 0,
                offset: 200
            });
            bg_wrapper_scene
                .setClassToggle(".bg-parallax--intro", "stop-anim")
                .addTo(controller);

            var bg_wrapper_scene1 = new ScrollMagic.Scene({
                triggerElement: ".section-works",
                triggerHook: 0,
                offset: 200
            });
            bg_wrapper_scene1.setClassToggle(".code", "stop-fixed").addTo(controller);

            $(".scroll-trigger").each(function () {
                var loop_scene = new ScrollMagic.Scene({
                    triggerElement: "this",
                    triggerHook: 0.9,
                    reverse: false
                });
                loop_scene.setClassToggle(this, "fade-in").addTo(controller);
            });
            var opacity_scene = new ScrollMagic.Scene({
                triggerElement: ".section-works",
                triggerHook: 0.7,
                reverse: false
            });
            opacity_scene.setClassToggle(".svg-opacity", "animate").addTo(controller);

            var bg_about_scene = new ScrollMagic.Scene({
                triggerElement: ".section-about-us",
                triggerHook: 0.4,
                reverse: false
            });
            bg_about_scene
                .setClassToggle(".section-about-us .bg-wrapper", "fade-in-left")
                .addTo(controller);

            var svg_black = new ScrollMagic.Scene({
                triggerElement: ".section-works-single",
                duration: "100%",
                triggerHook: 0
            });
            svg_black
                .addTo(controller)
                .on("enter", function () {
                    if ($("body").hasClass("slide-black")) {
                        $(".svg-logo").addClass("black");
                    }
                })
                .on("leave", function () {
                    if ($("body").hasClass("slide-black")) {
                        $(".svg-logo").removeClass("black");
                    }
                });
            $(".fixed-logo a").on("click", function () {
                console.log("#1 .fixed-logo a");

                /*
                $body_burger.toggleClass("open");
                if ($burger.hasClass("open-cross")) {
                    $burger.removeClass("open open-cross");
                    $topbar_forms.removeClass("fade-in-nav");
                    if ($burger_wrapper.hasClass("animate-opacity")) {
                        $burger_wrapper.removeClass("animate-opacity");
                    }
                    if ($thumbnails.hasClass("fade-in-nav")) {
                        $thumbnails.removeClass("fade-in-nav");
                        $body.removeClass("open");
                        $thumbnail_button.removeClass("hide");
                    }
                    if (
                        $(".form-msg-block").hasClass("fade-in-msg-block") ||
                        $(".form-msg-block").hasClass("fade-in-up-msg-block")
                    ) {
                        $(".form-msg-block")
                            .removeClass("fade-in-msg-block")
                            .removeClass("fade-in-up-msg-block");
                        $(".form-msg")
                            .text("")
                            .removeClass("error")
                            .removeClass("success");
                        $(".msg-check").removeClass("pulse-check");
                    }
                } else {
                    $topbar_info.toggleClass("fade-in-nav");
                }
                if ($desktop_topbar.hasClass("animate-top")) {
                    $desktop_topbar.removeClass("animate-top");
                }
                $desktop_topbar.show();
                */




                $burger.removeClass("open open-cross");
                $topbar_forms.removeClass("fade-in-nav");
                if ($burger_wrapper.hasClass("animate-opacity")) {
                    $burger_wrapper.removeClass("animate-opacity");
                }
                if ($thumbnails.hasClass("fade-in-nav")) {
                    $thumbnails.removeClass("fade-in-nav");
                    $body.removeClass("open");
                    $body.removeClass("open-thmb");
                    $thumbnail_button.removeClass("hide");
                }

                if ($desktop_topbar.hasClass("animate-top")) {
                    $desktop_topbar.removeClass("animate-top");
                }
                $desktop_topbar.show();

                $("html, body").animate({
                    scrollTop: 0
                });
            });
        }
        if ($(".fp-services").length) {
            var _bg_wrapper_scene = new ScrollMagic.Scene({
                triggerElement: ".inline-card",
                triggerHook: 0.9,
                reverse: false
            });
            _bg_wrapper_scene
                .setClassToggle(".inline-card", "fade-in-up")
                .addTo(controller);
        }
        $(".back-to-top").on("click", function () {
            $("html,body").animate(
                {
                    scrollTop: 0
                },
                700
            );
        });

        /*----------------------------------------------------------
                                 VALIDATE FORM
        ---------------------------------------------------------*/
        $("#submit").click(function () {
            function failMsg() {
                $form_msg_block.addClass("fade-in-up-msg-block");
                $form_msg.hide();
                $form_error.show();
            }

            var radio_val = $("input[name=radioName]:checked").val(),
                $msg_area = $("#textArea"),
                msg_val = $msg_area.val(),
                $name = $("#name"),
                name_val = $name.val(),
                $email = $("#email"),
                email_val = $email.val(),
                $tel = $("#tel"),
                tel_val = $tel.val(),
                hasError_name = true,
                $form_msg_block = $(".form-msg-block"),
                hasError_msgArea = false,
                $form_msg = $(".form-msg"),
                $form_error = $(".form-error"),
                $pos = $("#position");
            if (msg_val.length < 3) {
                $msg_area.removeClass("form-valid").addClass("form-invalid");
                hasError_msgArea = true;
            } else {
                $msg_area.removeClass("form-invalid").addClass("form-valid");
                hasError_msgArea = false;
            }

            hasError_name = true;
            if (!ws_validateName(name_val) || name_val.length < 3) {
                $name.removeClass("form-valid").addClass("form-invalid");
                hasError_name = true;
            } else {
                $name.removeClass("form-invalid").addClass("form-valid");
                hasError_name = false;
            }

            var hasError_email = true;
            if (!ws_validateEmail(email_val) || email_val.length < 3) {
                $email.removeClass("form-valid").addClass("form-invalid");
                hasError_email = true;
            } else {
                $email.removeClass("form-invalid").addClass("form-valid");
                hasError_email = false;
            }

            var hasError_tel = true;
            if (!ws_validateTel(tel_val) || tel_val.length < 3) {
                $tel.removeClass("form-valid").addClass("form-invalid");
                hasError_tel = true;
            } else {
                $tel.removeClass("form-invalid").addClass("form-valid");
                hasError_tel = false;
            }
            var hasError_pos = false;
            console.log($("#position:selected").val());
            if ($pos.length && $("#position option:selected").val() === "") {
                $pos.removeClass("form-valid").addClass("form-invalid");
                hasError_pos = true;
            } else {
                $pos.removeClass("form-invalid").addClass("form-valid");
                hasError_pos = false;
            }

            if (hasError_name === false && hasError_email === false && hasError_tel === false && hasError_msgArea === false && hasError_pos === false) {
                if ($(".fp-home").length) {
                    $.post("php/process.php", {
                        action: "contacthomepage",
                        recaptcha_response: $("#recaptchaResponse").val(),
                        radio: radio_val,
                        textarea: msg_val,
                        name: name_val,
                        email: email_val,
                        tel: tel_val,
                        ws_ajax_send_form: 1,
                        ws_chack: "fg" + "yu"
                    }, function (data) {
                        if (data == "OK") {
                            console.log("data", data);
                            //$topbar_forms.removeClass("fade-in-nav");
                            //$form_msg_block.removeClass("fade-in-up-msg-block").addClass("fade-in-msg-block");
                            $topbar_forms.hide();
                            $form_msg_block.show();
                            $(".msg-check").addClass("pulse-check");
                            $form_msg.show();
                            $form_error.hide();
                            $msg_area.val("").removeClass("form-valid");
                            $name.val("").removeClass("form-valid");
                            $email.val("").removeClass("form-valid");
                            $tel.val("").removeClass("form-valid");
                            $("#files-uploaded-div").children().remove();
                            $(".button-text").show();

                            setTimeout(function () {
                                $topbar_forms.show();
                                $form_msg_block.hide();
                            }, 5000);
                        } else {
                            failMsg();
                        }
                    });
                } else if ($(".fp-services").length) {
                    $.post("php/process.php", {
                        action: "candidateform",
                        recaptcha_response: $("#recaptchaResponse").val(),
                        select: $("#position option:selected").val(),
                        textarea: msg_val,
                        name: name_val,
                        email: email_val,
                        tel: tel_val,
                        ws_ajax_send_form: 1,
                        ws_chack: "fg" + "yu"
                    }, function (data) {
                        if (data == "OK") {
                            console.log("data", data);
                            //$topbar_forms.removeClass("fade-in-nav");
                            //$form_msg_block.removeClass("fade-in-up-msg-block").addClass("fade-in-msg-block");
                            $topbar_forms.hide();
                            $form_msg_block.show();
                            $(".msg-check").addClass("pulse-check");
                            $form_msg.show();
                            $form_error.hide();
                            $msg_area.val("").removeClass("form-valid");
                            $name.val("").removeClass("form-valid");
                            $email.val("").removeClass("form-valid");
                            $tel.val("").removeClass("form-valid");
                            $("#files-uploaded-div").children().remove();
                            $(".button-text").show();

                            setTimeout(function () {
                                $topbar_forms.show();
                                $form_msg_block.hide();
                            }, 5000);
                        } else {
                            failMsg();
                        }
                    });
                }

            } else {
                failMsg();
            }

            return false;
        });

        function ws_validateName(name_val) {
            var nameReg = /^[a-z ,."-]+$/i;
            if (!nameReg.test(name_val)) {
                //$(".input").css("border", "1px rgba(165, 52, 52, 0.76) solid");
            } else {
                return true;
            }
        }

        function ws_validateEmail(email_val) {
            var emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
            if (!emailReg.test(email_val)) {
                //$(".input-group .email1").css("border", "1px rgba(165, 52, 52, 0.76) solid");
            } else {
                return true;
            }
        }

        function ws_validateTel(tel_val) {
            //var telReg = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
            //var telReg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            var telReg = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
            if (!telReg.test(tel_val)) {
                //$(".input-group .email1").css("border", "1px rgba(165, 52, 52, 0.76) solid");
            } else {
                return true;
            }
        }

        /***** VALIDATE FORM *****/

        $(window).scroll(function () {
            if ($(".fp-home").length) {
                var $logo = $(".intro-wrapper .svg-logo"),
                    scrollHeight = $logo.offset().top - 10,
                    scrollPosition = $(window).scrollTop();

                if (scrollPosition >= scrollHeight) {
                    $(".fixed-logo").removeClass("hidden");
                } else {
                    $(".fixed-logo").addClass("hidden");
                }
            }
        });


        $("#share").jsSocials({
            shareIn: "popup",
            showLabel: false,
            showCount: false,
            shares: ["facebook", "linkedin"]
        });
        $(".share-button").on("click", function () {
            $(".jssocials ").toggleClass("lets-share");
        });
    });
})();
$(document).ready(function(){
    $(this).scrollTop(0);
}); 
